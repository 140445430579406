.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px 25px 20px;
}

.loginContainer  h3 {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
}

.loginContainer h4 {
    width: 100%;
    margin-top: 5px;
    color: #8a8a8a;
    font-weight: normal;
}

.card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid; */
    box-shadow: 2px 2px 4px #ccc;
    background: white;
}

.forgotPWCard {
    padding: 10px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid; */
    box-shadow: 2px 2px 4px #ccc;
    background: white;
    align-self: center;
    min-width: 315px;
}

.forgotPWCard h3 {
    margin-bottom: 25px;
}

.formInput {
    border: none;
    box-shadow: none;
    background: #efefef;
    width: 100%;
    font-size: 16px;
    padding: 10px 10px;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 25px;
}

.logInWithGoogleContainer {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.authForgotPassword {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.authForgotPassword a {
    color: #2196f3
}

.authForgotPassword a:first-of-type {
    margin-right: 15px
}

.errorMsg {
    font-size: 14px;
    color: salmon;
    margin-bottom: 10px;
}

.loginForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.loginForm label {
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin-bottom: 8px;
}


.styledSubmitButton {
    padding: 8px 19px;
    font-size: 15px;
    background: #2196f3;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    float: right;
    cursor: pointer;
}

.styledSubmitButton:hover {
    background: #48acf5;
}

.styledCancelButton {
    padding: 8px 19px;
    font-size: 15px;
    background: #fff;
    border: none;
    border-radius: 3px;
    color: #000;
    font-weight: 500;
    float: right;
    cursor: pointer;
    margin-right: 10px;
}

.styledCancelButton:hover {
    background: #efefef;
}
.buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}