.formInput {
    border: none;
    box-shadow: none;
    background: #efefef;
    width: 100%;
    font-size: 16px;
    padding: 10px 10px;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 25px;
}
.formInputPW {
    border: none;
    box-shadow: none;
    background: #efefef;
    width: 100%;
    font-size: 16px;
    padding: 10px 10px;
    border-bottom: 1px solid #d6d6d6;
}
h3 {
    margin-bottom: 0;
}

.subtitle {
    font-size: 12px;
    margin-bottom: 20px;
    color: #a6a6a6;
}

.errorMsg {
    float: left;
    color: salmon;
    font-size: 11px;
    width: 100%;
}

.card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid; */
    box-shadow: 2px 2px 4px #cc c;
    background: white;
    width: 100%;
    max-width: fit-content;
}

.signUpForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.signUpForm label {
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin-bottom: 8px;
}

.styledSubmitButton {
    padding: 8px 19px;
    font-size: 15px;
    background: #2196f3;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    float: right;
    cursor: pointer;
}

.styledSubmitButton:disabled {
    background: #ccc;
    color: #929292;
}

.passwordInput {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
}

.passwordInput input {
    margin-right: 10px;
}

.styledSubmitButton:disabled:hover {
    background: #ccc;
    color: #929292;
    cursor: not-allowed;
}

.styledSubmitButton:hover {
    background: #48acf5;
}

.styledCancelButton {
    padding: 8px 19px;
    font-size: 15px;
    background: #fff;
    border: none;
    border-radius: 3px;
    color: #000;
    font-weight: 500;
    float: right;
    cursor: pointer;
    margin-right: 10px;
}

.styledCancelButton:hover {
    background: #efefef;
}
.buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}