.appWrapper {
    width: 100%;
    height: 100%;
}

.logOutBtn {
    padding: 8px 19px;
    font-size: 15px;
    background: #2196f3;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    float: right;
    cursor: pointer;
}