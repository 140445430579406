
.loaderCont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    top: 100px;
}

.answeredContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 0 20px;
}

.answeredContainer button {
    padding: 8px 19px;
    font-size: 15px;
    background: #2196f3;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    float: right;
    cursor: pointer;
    width: 150px;
}

.answeredContainer button:hover {
    background: #48acf5;
}

.answeredContainer strong {
    color: salmon;
}

.nameInputs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
}

.nameInputs label {
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin-bottom: 8px;
}

.instructions {
    text-transform: uppercase;
    color: #797979;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 14px;

}
.nameInputs input {
    border: none;
    box-shadow: none;
    background: #efefef;
    width: 100%;
    font-size: 16px;
    padding: 10px 10px;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 25px;
}

.nameInputs .inputGroup {
    display: flex;
    flex-direction: column;
    width: 47%;
}

.radioCont {
    display: flex;
    align-items: center;
}

.radioGroup {
    display: flex;
}

.radioGroup .radioCont:last-of-type {
    margin-left: 20px;
}

.radioCont label {
    margin-left: 10px
}

@media (max-width: 768px) {
    .nameInputs {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
    }

    .nameInputs .inputGroup {
        width: 90%
    }

    .nameInputs input {
        width: 100%;
    }

}